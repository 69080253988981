<template>
  <div data-app>
    <v-app-bar light>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <router-link :to="'/conditions/'">
        <v-img
          class="mx-2"
          src="@/assets/pumas-brand-header.svg"
          max-height="100"
          max-width="100"
          contain
        ></v-img>
      </router-link>

      <v-toolbar-title> Lyv<sup>®</sup> CDS</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="routeTo('Condition')" id="home" name="home">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" id="dots" name="dots">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <!-- <v-list-item>
            <v-list-item-title @click="routeTo('Profile')" class="cp"
              >Profile</v-list-item-title
            >
          </v-list-item> -->
          <v-list-item>
            <v-list-item-title @click="logout()" class="cp"
              >Logout</v-list-item-title
            >
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-title>User Profile</v-list-item-title>
          </v-list-item> -->
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-avatar color="#EF515B" size="56">{{
              userName | firstLetter
            }}</v-avatar>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="subtitlestyle">
              {{ userName }}
            </v-list-item-title>
            <v-list-item-subtitle class="subtitlestyle">{{
              email
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="theme--text text--accent-4"
        >
          <v-list-item @click="routeTo('Condition')">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="userRole === 'admin'" @click="routeTo('Manage')">
            <v-list-item-title>Manage</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-title>Notifications</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
      <!-- <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout()">
            Logout
          </v-btn>
        </div>
      </template> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import utils from '../../services/utils';
import { mapGetters } from 'vuex';
import config from "../../config/instance";

export default {
  name: 'app-header',
  computed: {
    ...mapGetters(['userRole']),
  },
  data() {
    return {
      drawer: false,
      group: null,
      userName: utils.getLocalStoragevalue('name')
        ? utils.getLocalStoragevalue('name')
        : '',
      email: utils.getLocalStoragevalue('email')
        ? utils.getLocalStoragevalue('email')
        : '',
      options: [{ text: 'Profile', method: 'profile' }],
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    logout() {
      let script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      window.FreshworksWidget("identify", "ticketForm", {
        name: "",
        email: "",
      });
      window.FreshworksWidget('close');
      let myScript = document.createElement("script");
      myScript.setAttribute("type", "text/javascript");
      myScript.setAttribute(
        "src",
       `${config.Freshwork.Widget_Url}`
      );
      document.head.appendChild(myScript);
      document.head.appendChild(script);
      this.$store
        .dispatch('logout')
        .then(() => {
          localStorage.clear();
          this.$router.push({ name: 'Login' });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    routeTo(name) {
      this.$router.push({ name });
    },
  },
  filters: {
    capitalize: function(value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    firstLetter: function(value) {
      return value.charAt(0);
    },
  },
};
</script>

<style type="text/css">
.deep-purple--text {
  color: #ef515b !important;
  caret-color: #ef515b !important;
}
.cp {
  cursor: pointer !important;
}
.subtitlestyle {
  text-transform: none;
}
</style>
